import { useToast } from '@/components/shared/toast/useToast';
import { useGlobalStore } from '@/stores/globalStore';
import axios from 'axios';
import { useEffect } from 'react';
import { useQuery } from 'react-query';

// export function useProject() {
//     const params = useParams<{ workspaceId: string, projectId: string }>()

//     const [paramsState, setParamsState] = useState(params)

//     useEffect(() => {
//         if (!isEqual(paramsState, params)) {
//             setParamsState(params)
//         }
//     }, [params])

//     return useMemo(() => ({
//         workspaceId: Number(paramsState.workspaceId),
//         projectId: Number(paramsState.projectId)
//     }), [paramsState])
// }

export function useProject() {
    const project = useGlobalStore(state => state.project)
    const setProject = useGlobalStore(state => state.setProject)
    const { enqueueToast } = useToast()


    const { data } = useQuery(
        ['settings'],
        () => axios.get('/settings.json'),
        {
            onError: () => {
                enqueueToast({ title: 'Ошибка!', message: 'Не удалось загрузить настройки' }, { variant: 'error' })
            }
        }
    )

    useEffect(() => {
        if (!data) {
            return
        }

        const { workspaceId, projectId } = data.data

        if (!workspaceId || !projectId) {
            return
        }

        setProject({
            projectId: Number(projectId),
            workspaceId: Number(workspaceId)
        })
    }, [data?.data])

    return project
}