import styled, { css } from 'styled-components'
import { mediaMin } from '@/ui/media';

type GridSize = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
type JustifyContent = "flex-start" | "center" | "flex-end" | "space-between" | "space-around"
type AlignItems = "flex-start" | "center" | "flex-end"

type GridProps = {
    container?: boolean
    item?: boolean
    size?: GridSize
    xs?: GridSize
    sm?: GridSize
    md?: GridSize
    lg?: GridSize
    xl?: GridSize
    spacing?: number
    justify?: JustifyContent
    align?: AlignItems
}

const Grid: React.FC<GridProps & React.HTMLAttributes<HTMLDivElement>> = ({
    children, container, item, size, spacing, xs, sm, md, lg, xl, justify, align, ...otherProps
}) => {
    return (
        <GridBase
            $container={container}
            $item={item}
            $size={size}
            $xs={xs}
            $sm={sm}
            $md={md}
            $lg={lg}
            $xl={xl}
            $spacing={spacing}
            $justifyContent={justify}
            $alignItems={align}
            className={container ? 'grid-container' : ''}
            {...otherProps}
        >
            {children}
        </GridBase>
    )
}

export default Grid

const GridItem = css`
    display: block;
`

const GridContainer = css`
    display: flex;
    flex-wrap: wrap;
    flex: 1 0 auto;
`

const GridRoot = styled.div``

const GridBase = styled(GridRoot)<{
    $container?: boolean
    $item?: boolean
    $size?: GridSize
    $xs?: GridSize
    $sm?: GridSize
    $md?: GridSize
    $lg?: GridSize
    $xl?: GridSize
    $spacing?: number
    $justifyContent?: JustifyContent
    $alignItems?: AlignItems
} & React.HTMLAttributes<HTMLDivElement>>`
    ${({ $container }) => $container && GridContainer};
    ${({ $item }) => $item && GridItem};
    ${({ $justifyContent }) => $justifyContent && css`
        justify-content: ${$justifyContent};
    `};
    ${({ $alignItems }) => $alignItems && css`
        align-items: ${$alignItems};
    `};
    ${({ $size }) => $size && css`
        flex-basis: ${$size / 12 * 100}%;
    `};
    ${({ $spacing = 1, theme }) => $spacing && css`
        ${GridRoot} {
            padding: ${$spacing * theme.grid.gutter}px;
            // padding-top: ${$spacing * theme.grid.gutter}px;
        }

        &.grid-container {
            margin: 0 -${$spacing * theme.grid.gutter}px;
            // margin: -${$spacing * theme.grid.gutter}px;
            width: 100%;
            width: calc(100% + ${$spacing * theme.grid.gutter}px);
            padding: 0;
        }
    `};

    ${({ $xs }) => $xs && css`
        ${mediaMin.xs`
            flex-basis: ${$xs / 12 * 100}%;
        `}
    `};

    ${({ $sm }) => $sm && css`
        ${mediaMin.sm`
            flex-basis: ${$sm / 12 * 100}%;
        `}
    `};

    ${({ $md }) => $md && css`
        ${mediaMin.md`
            flex-basis: ${$md / 12 * 100}%;
        `}
    `};

    ${({ $lg }) => $lg && css`
        ${mediaMin.lg`
            flex-basis: ${$lg / 12 * 100}%;
        `}
    `};

    ${({ $xl }) => $xl && css`
        ${mediaMin.xl`
            flex-basis: ${$xl / 12 * 100}%;
        `}
    `};
`