import React from 'react'
import styled, { css } from 'styled-components'

export const TreeListStyles = css`
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 1.6rem;
    color: ${props => props.theme.palette.text.primary};
    position: relative;
`

const TreeList = styled.ul<{ $expanded?: boolean }>`
    ${TreeListStyles};
    transition: all 0.5s ease-out;
    overflow: hidden;

    ul {
        padding-left: 2rem;
    }

    ${({ $expanded }) => $expanded ? css`
        max-height: auto;
    ` : css`
        max-height: 0;
    `}
`

export default TreeList