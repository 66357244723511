import { useLayoutEffect, useState } from 'react';

function useWindowSize() {
    const [size, setSize] = useState([0, 0, 0, 0])

    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight, window.screen.availWidth, window.screen.availHeight])
        }

        window.addEventListener('resize', updateSize)
        window.addEventListener('orientationchange', updateSize)

        updateSize()

        return () => {
            window.removeEventListener('resize', updateSize)
            window.removeEventListener('orientationchange', updateSize)
        }
    }, [])

    return {
        width: size[0],
        height: size[1],
        availWidth: size[2],
        availHeight: size[3],
    }
}

export default useWindowSize