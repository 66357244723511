import { useReport } from '@/api/hooks/useReport'
import { IReport } from '@/api/services/report.service'
import Close from '@/components/Close'
import Grid from '@/components/Grid'
import Modal from '@/components/Modal/Modal'
import FormLoader from '@/components/ui/form/FormLoader'
import { useProjectStore } from '@/stores/projectStore'
import Pagination from '@/ui/components/Pagination/Pagination'
import media from '@/ui/media'
import { formatToReport } from '@/utils/helpers/dates.helpers'
import React, { useMemo, useState } from 'react'
import { CSVLink } from 'react-csv'
import styled from 'styled-components'
import { printPDF } from '@/utils/func/print'
import { useIntl } from 'react-intl'
import { translate } from '@/i18n'
import { BomjMapper } from '@/components/layout/Sidebar/Reports/reports/bomj-report/bomj.mapper'
import { useUserCardFields } from '@/api/hooks/useUserCardFields'

type ReportModalProps = {
  open: boolean
  report: IReport
  onClose: () => void
}

const BomjReportView: React.FC<ReportModalProps> = ({
  report,
  open,
  onClose
}) => {
  const [currentPage, setCurrentPage] = useState<number>(1)

  const nodes = useProjectStore((state) => state.nodes)
  const intl = useIntl()
  const { data, isLoading } = useReport({
    reportId: report.report_id,
    params: {
      withExtensions: true
    }
  })

  const { data: userFields } = useUserCardFields()
  const userProps =
    useMemo(
      () =>
        userFields?.fields
          .filter((v) => v.alias != 'booking_category')
          .map((field) => ({
            label: field.label,
            key: field.uid
          })),
      [userFields]
    ) || []
  const columns = useMemo(() => BomjMapper.getColumns(data?.report), [data])
  const propsTranslations = useMemo(
    () => BomjMapper.getTranslations(columns, userProps),
    [columns, userProps]
  )
  const translations = {
    type_name: intl.formatMessage({ id: 'report-type_name' }),
    name: intl.formatMessage({ id: 'report-name' }),
    parent: intl.formatMessage({ id: 'report-parent' }),
    display: intl.formatMessage({ id: 'employee' }),
    ...propsTranslations
  }

  const reportItems = data?.report || []

  const formatData = useMemo(() => {
    return data
      ? reportItems.map((item) => {
          const nodeType = nodes.find((node) => node.id == item.parent)

          return {
            ...item,
            parent: nodeType?.name
          }
        })
      : []
  }, [reportItems])

  const pdfCols = useMemo(
    () => columns.map((col) => ({ header: translations[col], dataKey: col })),
    [columns]
  )

  return (
    <Modal isOpen={open} onClose={onClose}>
      <Header>
        <Title>
          {translate('report-template', {
            name: intl.formatMessage({ id: report.name })
          })}
        </Title>
        <ExportWrapper>
          <ReportButton
            onClick={() =>
              printPDF({
                name: report.name,
                columns: pdfCols,
                body: formatData
              })
            }
          >
            PDF
          </ReportButton>
          <CSVLink
            data={formatData || ''}
            headers={columns.map((col) => ({
              label: translations[col],
              key: col
            }))}
            separator={';'}
            filename={`${report.name} ${formatToReport(new Date())}`}
          >
            <ReportButton>CSV</ReportButton>
          </CSVLink>
          <Close color="#000" onClick={onClose} />
        </ExportWrapper>
      </Header>

      <Grid style={{ position: 'relative' }}>
        <Grid.RowHeader $cols={`repeat(${columns.length}, minmax(50px, 1fr))`}>
          {columns.map((col) => (
            <Grid.Item key={col}>{translations[col]}</Grid.Item>
          ))}
        </Grid.RowHeader>

        {reportItems.length ? (
          reportItems
            .slice((currentPage - 1) * 20, currentPage * 20)
            .map((spot) => (
              <ReportItem key={spot.id} columns={columns} item={spot} />
            ))
        ) : (
          <NotFound>{translate('no-results')}</NotFound>
        )}

        <FormLoader isLoading={isLoading} />
      </Grid>

      <Pagination
        currentPage={currentPage}
        total={reportItems.length || 0}
        handlePageChange={setCurrentPage}
      />
    </Modal>
  )
}

export default BomjReportView

const ReportItem = ({ item, columns }) => {
  // console.log(item, columns)
  const nodes = useProjectStore((state) => state.nodes)

  const cols = columns
    .map((column) => {
      let data = item[column]

      if (!data) return null

      if (column == 'parent') {
        const uid = data
        const nodeType = nodes.find((node) => node.id == data)
        data = nodeType?.name || uid
      }

      return {
        key: column,
        value: data
      }
    })
    .filter((v) => v)

  return (
    <Grid.Row $cols={`repeat(${columns.length}, minmax(50px, 1fr))`}>
      {cols.map(({ key, value }) => (
        <Grid.Item key={key}>{value}</Grid.Item>
      ))}
    </Grid.Row>
  )
}

const NotFound = styled.div`
  width: 100%;
  text-align: center;
  padding: 12px 0;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${media.lg`
        flex-direction: column;
        align-items: flex-start;
    `}
`

const ExportWrapper = styled.div`
  display: flex;
  align-items: center;

  * {
    &:not(:last-child) {
      margin-right: 0.8rem;
    }
  }

  ${media.lg`
        margin-top: 20px;
    `}
`

export const ReportButton = styled.button`
  border: 2px solid #000;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 1.6rem;
  line-height: 1.6rem;
  color: #000;
  padding: 1.2rem 2rem;
  outline: none;
  background: transparent;
  cursor: pointer;
`

const Title = styled.div`
  font-weight: 500;
  font-size: 2.4rem;
  line-height: 2.4rem;
  color: #000000;
`
