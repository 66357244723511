import React from 'react'

export const ShowValid: React.FC<{ valid: boolean }> = ({ valid, children }) => {

    if (!valid) return null

    return (
        <>
            {children}
        </>
    )
}