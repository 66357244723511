import { useGlobalStore } from '@/stores/globalStore'
import { useMapStore } from '@/stores/mapStore'
import { useEffect } from 'react'

const MapController = () => {
  const activeLayer = useGlobalStore((state) => state.activeLayer)
  const mapLayer = useMapStore((state) => state.mapLayer)
  const polygon = useMapStore((state) => state.polygon)
  const setPolygon = useMapStore((state) => state.setPolygon)

  // useEffect(() => {
  //   const predicate = activeLayer != mapLayer && mapLayer && mapLayer > 0
  //   const shouldAnimate = Boolean(predicate)
  //
  //   if (!shouldAnimate) {
  //     setPolygon(null)
  //     return
  //   }
  //
  //   setPolygon(activeLayer)
  // }, [activeLayer, mapLayer])

  const onLayerChange = (event) => {
    const {
      detail: { layerId }
    } = event
    setPolygon(layerId)
  }

  useEffect(() => {
    document.addEventListener('layer-change', onLayerChange, true)

    return () =>
      document.removeEventListener('layer-change', onLayerChange, true)
  }, [])

  useEffect(() => {
    const current = polygon == activeLayer
    if (!current) {
      setPolygon(null)
    }
  }, [activeLayer, polygon])

  return null
}

export default MapController
