import useResponsive from '@/hooks/useResponsive'
import React from 'react'
import Search, { SearchMobileButon } from './Search'

const SearchBar = () => {
    const { isMobile } = useResponsive()

    if (isMobile) {
         return <SearchMobileButon />
    }

    return <Search />
}

export default SearchBar