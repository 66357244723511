export const lowerCaseString = (str: string | null | undefined) => {
  if (!str || typeof str !== 'string') return '';

  return str.toLowerCase();
};

export function isEmail(email: string) {
  var re = /\S+@\S+\.\S+/;

  return re.test(email);
}