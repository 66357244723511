import { Layer } from "react-konva"
import { memo, useMemo } from "react";
import { AnimatedPoint } from "./Point";
import { PointType } from "@/api/services/layer.service";
import { useMapStore } from "@/stores/mapStore";
import { MetablockNodes } from '@/api/services/project.service';
import { LayerBookingItem } from '@/api/services/booking.service';

interface PointsLayerProps {
    colors?: any
    options?: {
        labelSize: number
        fontSize: number
        color: string
        borderWidth: number
        wrapText: boolean
    }
    points?: PointType[]
    nodes?: MetablockNodes
    bookings?: LayerBookingItem[]
}

const defaultOptions = {
    labelSize: 0.015,
    fontSize: 12,
    color: "#ffffff",
    borderWidth: 0.1,
    wrapText: false
}

const PointsLayer: React.FC<PointsLayerProps> = ({
                                                     colors,
    nodes = {},
    options = defaultOptions,
    bookings = [],
    points = []
}) => {
    const [width, height] = useMapStore(state => state.size)

    if (width == 0 || height == 0) return null

    return (
        <Layer>
            {points.map((point) => (
                <AnimatedPoint
                    colors={colors}
                    key={point.id}
                    id={point.id}
                    point={point}
                    options={options}
                    nodes={nodes}
                    bookings={bookings}
                />
            ))}
        </Layer>
    )
}

export default memo(PointsLayer)

// PointsLayer.whyDidYouRender = true
