import React from 'react'
import styled from 'styled-components'
import MenuItem from './MenuItem'
import { RolesEnum, useUserStore } from '@/stores/userStore'
import {
  BookingIcon,
  ObjectsIcon,
  OfficeMapIcon,
  ReportsIcon,
  UsersIcon
} from '@/components/icons'
import useResponsive from '@/hooks/useResponsive'
import { useIntl } from 'react-intl'

const Menu: React.FC = () => {
  const role = useUserStore((state) => state.role)

  const { isMobile } = useResponsive()
  const intl = useIntl()

  if (isMobile) return null

  return (
    <Wrapper>
      <Container>
        <MenuItem
          name={intl.formatMessage({ id: 'brand-name' })}
          selector="layers"
          icon={OfficeMapIcon}
        />
        {role === RolesEnum.Admin && (
          <MenuItem
            name={intl.formatMessage({ id: 'bookings' })}
            selector="bookings"
            icon={BookingIcon}
          />
        )}
        <MenuItem
          name={intl.formatMessage({ id: 'employees' })}
          selector="employees"
          icon={UsersIcon}
        />
        <MenuItem
          name={intl.formatMessage({ id: 'objects' })}
          selector="elements"
          icon={ObjectsIcon}
        />
        {role === RolesEnum.Admin && (
          <MenuItem
            name={intl.formatMessage({ id: 'reports' })}
            selector="reports"
            icon={ReportsIcon}
          />
        )}
      </Container>
    </Wrapper>
  )
}

export default Menu

const MobileWrapper = styled.div`
  width: 100%;
  height: 75px;
  box-shadow: 0px 0px 40px rgba(84, 84, 84, 0.25);
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  transition: all 0.5s;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  background: ${(props) => props.theme.palette.background.default};
  color: ${(props) => props.theme.palette.text.primary};
  overflow: hidden;
`

const Wrapper = styled.div`
  width: 94px;
  box-shadow: 0px 0px 40px rgba(84, 84, 84, 0.25);
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  transition: all 0.5s;
  position: absolute;
  top: 50%;
  right: -94px;
  transform: translateY(-50%);
  /* border-left: 1px solid ${(props) => props.theme.palette.text.primary}; */
  background: ${(props) => props.theme.palette.background.default};
  color: ${(props) => props.theme.palette.text.primary};
  overflow: hidden;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
