import React from 'react'

const ReportsIcon = ({ width = 25, height = 31 }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 25 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path className='hover-fill' d="M15.0052 0H4.75C3.72229 0 2.73666 0.408258 2.00996 1.13496C1.28326 1.86166 0.875 2.84729 0.875 3.875V27.125C0.875 28.1527 1.28326 29.1383 2.00996 29.865C2.73666 30.5917 3.72229 31 4.75 31H20.25C21.2777 31 22.2633 30.5917 22.99 29.865C23.7167 29.1383 24.125 28.1527 24.125 27.125V9.11981C24.1249 8.606 23.9207 8.11327 23.5573 7.75L16.375 0.567688C16.0117 0.204311 15.519 0.000109735 15.0052 0V0ZM15.4062 6.78125V2.90625L21.2188 8.71875H17.3438C16.8299 8.71875 16.3371 8.51462 15.9737 8.15127C15.6104 7.78792 15.4062 7.29511 15.4062 6.78125ZM16.375 26.1562V14.5312C16.375 14.2743 16.4771 14.0279 16.6587 13.8462C16.8404 13.6646 17.0868 13.5625 17.3438 13.5625H19.2812C19.5382 13.5625 19.7846 13.6646 19.9663 13.8462C20.1479 14.0279 20.25 14.2743 20.25 14.5312V26.1562C20.25 26.4132 20.1479 26.6596 19.9663 26.8413C19.7846 27.0229 19.5382 27.125 19.2812 27.125H17.3438C17.0868 27.125 16.8404 27.0229 16.6587 26.8413C16.4771 26.6596 16.375 26.4132 16.375 26.1562ZM11.5312 27.125C11.2743 27.125 11.0279 27.0229 10.8462 26.8413C10.6646 26.6596 10.5625 26.4132 10.5625 26.1562V18.4062C10.5625 18.1493 10.6646 17.9029 10.8462 17.7212C11.0279 17.5396 11.2743 17.4375 11.5312 17.4375H13.4688C13.7257 17.4375 13.9721 17.5396 14.1538 17.7212C14.3354 17.9029 14.4375 18.1493 14.4375 18.4062V26.1562C14.4375 26.4132 14.3354 26.6596 14.1538 26.8413C13.9721 27.0229 13.7257 27.125 13.4688 27.125H11.5312ZM5.71875 27.125C5.46182 27.125 5.21542 27.0229 5.03374 26.8413C4.85206 26.6596 4.75 26.4132 4.75 26.1562V22.2812C4.75 22.0243 4.85206 21.7779 5.03374 21.5962C5.21542 21.4146 5.46182 21.3125 5.71875 21.3125H7.65625C7.91318 21.3125 8.15958 21.4146 8.34126 21.5962C8.52294 21.7779 8.625 22.0243 8.625 22.2812V26.1562C8.625 26.4132 8.52294 26.6596 8.34126 26.8413C8.15958 27.0229 7.91318 27.125 7.65625 27.125H5.71875Z" fill="#2C2C2C" />
        </svg>
    )
}

export default ReportsIcon