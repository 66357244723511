import { API, BASE_DOMAIN } from '@/api'
import { useGlobalStore } from '@/stores/globalStore'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Group, Rect, Text } from 'react-konva'
import useImage from 'use-image'
import { getMapCoord } from '../polygon/Polygon'
import { animated, Spring } from '@react-spring/konva'
import { easePoly } from 'd3-ease'
import { useMapStore } from '@/stores/mapStore'
import { RolesEnum, useUserStore } from '@/stores/userStore'
import { Portal } from 'react-konva-utils';
import ModalService from "@/components/shared/modal/ModalService";
import styled from "styled-components";
import {multiBookingsView} from "@/components/MultiBookingsView";

export const defaultMapPointImage = {
  owner_id: '15',
  image_id: '2d95a0df350c4c2dae7ab2c0c03bf337',
  extension: 'png'
}

export const getImageLink = (image) => {
  if (!image || !image.owner_id) return ''
  return `${BASE_DOMAIN}media/metablock/${image.owner_id}/${image.image_id}.SW100H100!default.${image.extension}`
}

const useMapAvatar = (bookingData, node) => {
  const imgSrc = bookingData
    ? API.user.getAvatar({ userId: Number(bookingData.user_id) })
    : getImageLink(node?.icon) || getImageLink(defaultMapPointImage)
  const [pointImg] = useImage(imgSrc, 'anonymous')

  return { image: pointImg }
}

export const isBookable = (data) => {
  if (!data) return false
  const plugin: any = Object.values(data).find((obj: any) => obj?.bookable)
  if (!plugin) return false
  return plugin.bookable
}

export const isBookableForMe = (data, categories) => {
  if (!data) return false
  const plugin: any = Object.values(data).find((obj: any) => obj?.bookable)
  if (!plugin) return false
  const category = plugin.category
  const bookable = plugin.bookable
  const isAvailableForCategory = categories.includes(category)
  const isAvailableForBooking =
    isAvailableForCategory === true && bookable == true

  return isAvailableForBooking
}

const defaultNode = {
  background: '#ffffff',
  border: '#000000',
  radius: 50,
  uid: 'ae4c38a5a5994d8082029b51370111a3',
  name: 'Сервер'
}

const BookingsViewWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  max-height: 100%;
  max-width: 400px;
  min-height: 90px;
  width: 100%;
  z-index: 9101;
  overflow-y: auto;
  background: #ffffff;
  box-shadow: 0px -4px 4px rgba(0,0,0,0.25);
  border-radius: 5px;
`

const Point = ({ colors, point, options, nodes, bookings }) => {
  const categories = useUserStore((state) => state.categories)
  const textRef = useRef<any>(null)
  const groupRef = useRef<any>(null)
  const role = useUserStore(state => state.role)
  const [show, setShow] = useState<boolean>(false)

  const isAdmin = role === RolesEnum.Admin

  const { id, x, y, name, type_uid, plugin_data } = point
  const { labelSize, fontSize, color, borderWidth, wrapText } = options
  const bookingsArr = useMemo(
      () => bookings?.filter((book) => book.point_id == id) || [],
      [bookings, id]
  )
  const bookingData = useMemo(
    () => bookings?.find((book) => book.point_id == id),
    [bookings, id]
  )
  const avaliable = useMemo(
    () => isBookableForMe(plugin_data, categories),
    [plugin_data, categories]
  )

  const node = nodes[type_uid] || defaultNode
  const text = bookingData?.display || name
  // const text = isAdmin ? name : bookingData?.display || name
  // const text = name
  const displayText = wrapText ? text.split(' ').join('\n') : text

  const [coord, setCoord] = useState({ x: 0, y: 0, pX: 0 })
  const { image } = useMapAvatar(bookingData, node)

  const setSeat = useGlobalStore((state) => state.setSeat)
  const setSeatEmployee = useGlobalStore((state) => state.setSeatEmployee)
  const [width, height] = useMapStore((state) => state.size)
  const setTooltip = useMapStore((state) => state.setTooltip)

  const onSelect = useCallback(() => {
    if (bookingsArr.length > 1) {
      multiBookingsView({ bookings: bookingsArr, name })
      return
    }

    setSeat(Number(point.id))
    if (bookingData) {
      setSeatEmployee(Number(bookingData.user_id))
    } else {
      setSeatEmployee(null)
    }
  }, [setSeat, setSeatEmployee, bookingData, point])

  const onMouseEnterHandler = React.useCallback((e) => {
    const container = e.target.getStage()?.container()
    
    handleShow()
    if (container) {
      container.style.cursor = 'pointer'
    }
  }, [])

  const onMouseLeaveHandler = React.useCallback((e) => {
    const container = e.target.getStage()?.container()
    handleHide()
    if (container) {
      container.style.cursor = 'default'
    }
  }, [])

  const onGroupMouseEnterHandler = React.useCallback(
    (e) => {
      setTooltip(name)
    },
    [setTooltip, name]
  )

  const onGroupMouseLeaveHandler = React.useCallback(
    (e) => {
      setTooltip(null)
    },
    [setTooltip]
  )

  useEffect(() => {
    if (width > 0 && height > 0) {
      const pointX =
        -textRef.current?.textWidth / 2 + (width * labelSize) / 2 ||
        (width * labelSize) / 2
      setCoord({
        x: getMapCoord(width, x),
        y: getMapCoord(height, y),
        pX: Number(pointX)
      })
    }
  }, [textRef.current, image, width, height, show])

  useEffect(() => {
    if (!groupRef.current) return
    groupRef.current.on('mouseenter', onGroupMouseEnterHandler)
    groupRef.current.on('mouseleave', onGroupMouseLeaveHandler)
  }, [groupRef.current])

  const getColor = useMemo(() => {
    if (type_uid !== '01e65206fc7e4827ab656a7f9cea3ac8' && type_uid !== 'a733a46125954394b9bfa7b6cce0be7f') return null

    const departmentPlugins = Object.values(node.plugin_data).find(v => v?.['fields'])?.['fields']

    if (!departmentPlugins) return node?.border

    const departmentField = departmentPlugins.find(plugin => plugin.name === '#department')
    const departmentFieldId = departmentField?.id
    if (!departmentFieldId) return node?.border

    let department

    Object.values(point.plugin_data).forEach((plugin: any) => {
      if (plugin['field_' + departmentFieldId]) {
        department = plugin['field_' + departmentFieldId]
      }
    })

    const color = colors[department]

    if (!department || !color) return node?.border

    return color
  }, [point, node, colors])

  const handleShow = () => {
    // if (isAdmin) return
    if (bookingData?.display) {
      setShow(true)
    }
  }
  const handleHide = () => {
    // if (isAdmin) return
    setShow(false)
  }

  const handleViewBookings = () => {
    ModalService.open(MultiBookingsView, { backdrop: false })
  }

  const renderPoint = () => {
    if (type_uid === '9b120e10440848d89f435a454ec20e78') {
      return (
          <>
            <Rect
                width={textRef.current?.textWidth + 5}
                height={textRef.current?.textHeight + 5}
                stroke={getColor}
                strokeWidth={0.5}
                fill="#000"
                cornerRadius={node?.radius * 0.05}
                shadowForStrokeEnabled={false}
                perfectDrawEnabled={false}
                listening={true}
            />
            <Text
                ref={textRef}
                text={displayText}
                width={textRef.current?.textWidth + 5}
                height={textRef.current?.textHeight + 5}
                align="center"
                verticalAlign="middle"
                fontSize={fontSize}
                fill="#fff"
                listening={avaliable}
                perfectDrawEnabled={false}
            />
          </>
      )
    }

    if (bookingsArr.length > 1) {
      const namesSet = new Set(bookingsArr.map(book => book.display || ''))
      const names = [...namesSet].join('\n')
      return (
          <>
            <Group>
              <Rect
                  width={width * labelSize}
                  height={width * labelSize}
                  stroke="#000"
                  strokeWidth={fontSize * borderWidth}
                  fill="#fff"
                  cornerRadius={node?.radius}
                  shadowForStrokeEnabled={false}
                  perfectDrawEnabled={false}
                  listening={avaliable}
              />
              <Text
                  ref={textRef}
                  text={bookingsArr.length}
                  align="center"
                  verticalAlign="middle"
                  fontSize={fontSize * 2}
                  fill="#000"
                  x={width * labelSize / 2 - (fontSize / 2)}
                  y={width * labelSize / 2 - (fontSize)}
                  listening={true}
                  perfectDrawEnabled={false}
              />
            </Group>
            <Portal selector=".top-layer" enabled={show}>
              {show && (
                  <Group
                      x={coord.x + coord.pX - 2.5}
                      y={coord.y + width * 1.1 * labelSize}
                      offsetX={(width * labelSize) / 2}
                      offsetY={(width * labelSize) / 2}
                  >
                    <Rect
                        width={textRef.current?.width() + 5}
                        height={textRef.current?.height() + 5}
                        fill={'rgba(255,255,255,0.9)'}
                        cornerRadius={4}
                        shadowForStrokeEnabled={false}
                        perfectDrawEnabled={false}
                        listening={true}
                    />
                    <Text
                        ref={textRef}
                        text={names}
                        align="center"
                        verticalAlign="middle"
                        fontSize={fontSize}
                        fill={color}
                        x={2.5}
                        y={2.5}
                        listening={true}
                        perfectDrawEnabled={false}
                    />
                  </Group>
              )}
            </Portal>
          </>
      )
    }

    return (
        <>
            <Rect
                width={width * labelSize}
                height={width * labelSize}
                stroke={getColor}
                strokeWidth={fontSize * borderWidth}
                fill={node?.background}
                cornerRadius={node?.radius}
                shadowForStrokeEnabled={false}
                perfectDrawEnabled={false}
                listening={avaliable}
            />
          {image && (
              <Rect
                  width={width * labelSize}
                  height={width * labelSize}
                  cornerRadius={node?.radius}
                  fillPatternImage={image}
                  fillPatternScaleX={(width * labelSize) / image?.width}
                  fillPatternScaleY={(width * labelSize) / image?.width}
                  shadowForStrokeEnabled={false}
                  perfectDrawEnabled={false}
              />
          )}
              <Portal selector=".top-layer" enabled={show}>
                {show && (
                    <Group
                    // y={width * 1.1 * labelSize}
                    // x={coord.pX - 2.5}
                      x={coord.x + coord.pX - 2.5}
                      y={coord.y + width * 1.1 * labelSize}
                      offsetX={(width * labelSize) / 2}
                      offsetY={(width * labelSize) / 2}
                  >
                    <Rect
                        width={textRef.current?.width() + 5}
                        height={textRef.current?.height() + 5}
                        fill={'rgba(255,255,255,0.9)'}
                        cornerRadius={4}
                        shadowForStrokeEnabled={false}
                        perfectDrawEnabled={false}
                        listening={true}
                    />
                    <Text
                        // width={textRef.current?.width() + 5}
                        // height={textRef.current?.height() + 5}
                        ref={textRef}
                        text={displayText}
                        align="center"
                        verticalAlign="middle"
                        fontSize={fontSize}
                        fill={color}
                        x={2.5}
                        y={2.5}
                        listening={true}
                        perfectDrawEnabled={false}
                    />
                  </Group>
                )}
              </Portal>
      </>
    )
  }

  return (
    <Group
      x={coord.x}
      y={coord.y}
      offsetX={(width * labelSize) / 2}
      offsetY={(width * labelSize) / 2}
      onClick={onSelect}
      onTap={onSelect}
      listening={true}
    >
      <Group
          onMouseEnter={onMouseEnterHandler}
          onMouseLeave={onMouseLeaveHandler}
          // opacity={avaliable ? 1 : 0.3}
          id={'point' + point.id}
          ref={groupRef}
      >
        {renderPoint()}
      </Group>

    </Group>
  )
}

export const scaleRelativeToPoint = (stage, point, scale, onRescale) => {
  const scaleBy = 1.25
  const oldScale = stage.scaleX()

  const mousePointTo = {
    x: point.x / oldScale - stage.x() / oldScale,
    y: point.y / oldScale - stage.y() / oldScale
  }

  const newScale = scale
  const x = (point.x / newScale - mousePointTo.x) * newScale
  const y = (point.y / newScale - mousePointTo.y) * newScale

  onRescale({
    scale: newScale,
    point: {
      x,
      y
    }
  })
}

export const AnimatedPoint: React.FC<any> = React.memo((props) => {
  const { id } = props

  const seat = useGlobalStore((state) => state.seat)
  const currentSeat = useMemo(() => seat == id, [seat, id])

  return (
    <Spring
      from={{ opacity: 1 }}
      to={
        currentSeat
          ? [
              { opacity: 0.2 },
              { opacity: 1 },
              { opacity: 0.2 },
              { opacity: 1 },
              { opacity: 0.2 },
              { opacity: 1 },
              { opacity: 0.2 },
              { opacity: 1 }
            ]
          : { opacity: 1 }
      }
      config={{
        easing: easePoly.exponent(2),
        duration: 700
      }}
    >
      {(springProps) => (
        // @ts-ignore
        <animated.Group {...springProps}>
          <Point {...props} />
        </animated.Group>
      )}
    </Spring>
  )
})

AnimatedPoint.whyDidYouRender = true

export default React.memo(Point)
