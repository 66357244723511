import React from 'react'
import { FieldProps, getIn } from 'formik';
import { ErrorMessage, Input, InputFieldProps, InputProps, InputWrapper } from '@/ui/components/Field/Input';
import { ErrorBoundary } from 'react-error-boundary';


export const ModalSelectInputField = ({ field, form, textarea, ...props }: FieldProps & InputProps & InputFieldProps) => {
    const { customError, ...otherProps } = props
    const errorMessage = getIn(form.touched, field.name) && getIn(form.errors, field.name)

    return (
        <ErrorBoundary
            fallback={<></>}
        >
            <InputWrapper>
                <Input
                    {...field}
                    {...otherProps}
                    as={textarea ? 'textarea' : 'input'}
                    value={field?.value.name}
                    disabled
                    $error={errorMessage != null || customError != null}
                />

                {errorMessage && <ErrorMessage>{errorMessage?.id}</ErrorMessage>}
                {customError && <ErrorMessage>{customError}</ErrorMessage>}
            </InputWrapper>
        </ErrorBoundary>
    )
}