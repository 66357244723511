import styled from 'styled-components';

export const FormControls = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
  
  
    margin-top: 24px;
    max-width: 600px;
    width: 100%;

    & > {
        &:not(:last-child) {
            margin-right: 20px;
        }
    }
`

export const ControlsWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
`