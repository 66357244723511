import Loader from "@/components/Preloader/Loader"
import { animated, useTransition } from "react-spring"
import styled from "styled-components"
import { easePoly } from 'd3-ease';
import { translate } from '@/i18n';

const MapLoader: React.FC<{ isLoading: boolean }> = ({ isLoading }) => {
    const transitions = useTransition(isLoading, {
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        config: {
            duration: 300,
            easing: easePoly.exponent(2),
        },
    })

    return transitions((style, item) => item ? (
        <LoaderWrapper style={style}>
            <Loader />
            <LoaderText>
                {translate('map-loading')}...
            </LoaderText>
        </LoaderWrapper>
    ) : '')
}

export default MapLoader

const LoaderWrapper = styled(animated.div)`
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    z-index: 101;
    background: rgba(0, 0, 0, 0.35);

    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
`

const LoaderText = styled.div`
    margin-top: 20px;
    font-size: 16px;
    color: #fff;
`