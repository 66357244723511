import { useBookingsForLayer } from '@/api/hooks/useBookingsForLayer'
import { useLayerInfo } from '@/api/hooks/useLayerInfo'
import { useLayerView } from '@/api/hooks/useLayerView'
import { useMetadata } from '@/api/hooks/useMetadata'
import { useTree } from "@/api/hooks/useTree"
import { linkLayerInfoProperties } from '@/components/layout/Sidebar/Layers/LayerInfoModal'
import { checkHidden } from "@/components/layout/Sidebar/Layers/TreeItem"
import MapStage from '@/components/shared/map/stage/MapStage'
import { useGlobalStore } from '@/stores/globalStore'
import { useMapStore } from '@/stores/mapStore'
import { useProjectStore } from '@/stores/projectStore'
import { RolesEnum, useUserStore } from "@/stores/userStore"
import { formatLocalDateToAPI } from '@/utils/helpers/dates.helpers'
import axios from "axios"
import { addMinutes } from 'date-fns'
import { useEffect, useMemo, useState } from 'react'
import { useQuery } from "react-query"
import PointsLayer from '../point/PointsLayer'
import PolygonsLayer from '../polygon/PolygonsLayer'
import Tooltip from '../tooltip/Tooltip'
import MapCoverLayer from './MapCoverLayer'
import {Layer} from "react-konva";

const dispatchLoaded = () => {
  const event = new CustomEvent('map-loaded')
  document.dispatchEvent(event)
}

const MapContainer = () => {
  const setPopupLayer = useGlobalStore((state) => state.setPopupLayer)
// =======

  const activeLayer = useGlobalStore((state) => state.activeLayer);
  const selection = useGlobalStore((state) => state.selection);
  const nodes = useProjectStore((state) => state.nodes);
  const setMapLayer = useMapStore((state) => state.setMapLayer);
  const role = useUserStore((state) => state.role)

  const node = nodes.find((n) => n.id == activeLayer)
  const hasOwnView = node?.ownView
  const layer = hasOwnView ? node.id : node?.parent || activeLayer

  const [viewId, setViewId] = useState(layer)
  const { layers } = useTree()
  const { metadata } = useMetadata();

  const { data: layerInfo } = useLayerInfo(Number(layer))

  const { layerView, isSuccess: layerViewLoading } = useLayerView(Number(layer))
  const { data: settings } = useQuery(
      ['settings'],
      () => axios.get('/settings.json'),
  )
  const { data, isSuccess } = useBookingsForLayer(
    Number(layer),
    formatLocalDateToAPI(selection.startDate),
    formatLocalDateToAPI(addMinutes(selection.endDate, -30))
  )

  useEffect(() => {
    setMapLayer(Number(layer))
  }, [layer, activeLayer])

  useEffect(() => {
    if (isSuccess && layerViewLoading) {
      dispatchLoaded()
    }
  }, [isSuccess, layerViewLoading, activeLayer])

  const currentNode = useMemo(() => {
    if (layerInfo && metadata && metadata.layers) {
      return metadata.layers[layerInfo.info?.type_uid]
    }
    return null
  }, [layerInfo, metadata])

  const props = linkLayerInfoProperties(
    currentNode?.plugin_data,
    layerInfo?.info?.plugin_data
  )

  const isPopup = useMemo(
    () =>
      !!props.find((prop) => prop.name === 'popup' && prop.value === 'true'),
    [props]
  )

  useEffect(() => {
    if (node && isPopup) {
      setViewId(Number(node?.parent))
      setPopupLayer(Number(layer))
    } else if (node && !isPopup) {
      setViewId(layer)
    }
  }, [layer, isPopup, node])

  useEffect(() => {
    setMapLayer(Number(layer))
  }, [viewId, activeLayer])

  const accessablePolygons = useMemo(() => layerView?.polygons.filter(polygon => {
    const curr = layers?.nodes.find(n => n.id == polygon.id)
    if (!curr) return true

    const isHidden = checkHidden(curr, metadata)

    return role === RolesEnum.Admin ? true : !isHidden
  }), [layers?.nodes, layerView?.polygons])

  return (
    <MapStage>
      <MapCoverLayer view={layerView?.view} />
      <PolygonsLayer polygons={accessablePolygons} />
      <PointsLayer
          colors={settings?.data?.colors || {}}
          nodes={metadata?.rawNodes}
          points={layerView?.points}
          options={layerView?.options}
          bookings={data?.bookings}
      />
      <Layer name="top-layer" />
      <Tooltip />
    </MapStage>
  )
}

MapContainer.whyDidYouRender = true

export default MapContainer
