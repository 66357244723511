import { translate } from '@/i18n'
import { easePoly } from 'd3-ease'
import React from 'react'
import { animated, useTransition } from 'react-spring'
import styled from 'styled-components'

const FormLoader: React.FC<{ isLoading: boolean }> = ({ isLoading }) => {
    const transitions = useTransition(isLoading, {
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        config: {
            duration: 300,
            easing: easePoly.exponent(2),
        },
    })

    return transitions((style, item) => item ? (
        <LoaderWrapper style={style}>
            {translate('data-loading')}...
        </LoaderWrapper>
    ) : '')
}

export default FormLoader

const LoaderWrapper = styled(animated.div)`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 101;
    background: rgba(255, 255, 255, 0.9);

    display: flex;
    justify-content: center;
    align-items: center;
`