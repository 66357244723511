import create from 'zustand'
import { SectionType } from '@/containers/SidebarContainer'
import { TreeNodeData } from '@/components/Elements/tree'

export type IObjectType = {
    value: string
    label: string
}

type ProjectState = {
    nodes: TreeNodeData[]
    objectTypes: IObjectType[]
    fullMode: boolean
    setFullMode: (fullMode: boolean) => void
    setNodes: (nodes: TreeNodeData[]) => void
    setObjectTypes: (objectTypes: IObjectType[]) => void
}

export const useProjectStore = create<ProjectState>(set => ({
    nodes: [],
    objectTypes: [],
    fullMode: true,
    setFullMode: (fullMode) => set(() => ({ fullMode })),
    setNodes: (nodes) => set(() => ({ nodes })),
    setObjectTypes: (objectTypes) => set(() => ({ objectTypes }))
}))
